import React, { useState } from 'react';
import 'twin.macro';
import CountryMenu from './country-navigation-menu';

const SportsMenu = ({ sport, countries }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <li
      className="group"
      tw="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a href={`#${sport.toLowerCase()}`} tw="text-white">
        {sport}
      </a>
      {isDropdownOpen && (
        <ul tw="absolute left-1/2 px-2 transform -translate-x-1/2 bg-white text-gray-800 py-2 shadow-lg flex flex-row z-50">
          {countries.map((country, index) => (
            <CountryMenu
              key={index}
              name={country.title}
              leagues={country.entries}
              icon={country.icon}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default SportsMenu;
