import React, { useState } from 'react';
import 'twin.macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';


const ClubsMenu = ({ clubs }) => {
  const [isClubsDropdownOpen, setIsClubsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsClubsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsClubsDropdownOpen(false);
  };

  return (
    <ul
      tw="absolute top-full mx-auto w-96 mt-0 bg-white text-gray-800 py-2 shadow-lg flex flex-row flex-wrap"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {clubs.map((club, index) => {

        const { title, slug, icon } = club
        const image = getImage(icon.childImageSharp.gatsbyImageData);

        return (
          <li
            key={index}
            tw="block px-4 py-2 hover:bg-gray-200 whitespace-nowrap flex"
          >
            <GatsbyImage image={image} tw="my-auto mx-1" />
            <a href={`${slug.toLowerCase().replace(/ /g, '-')}`}>
              {title}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default ClubsMenu;
