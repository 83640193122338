import React, { useState } from 'react';
import 'twin.macro';
import LeaguesMenu from './league-navigation-menu';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CountryMenu = ({ name, leagues, icon }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const image = getImage(icon.childImageSharp.gatsbyImageData);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <li
      className="group"
      tw="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a
        href={`#${name.toLowerCase()}`}
        tw="hover:(bg-gray-200 rounded-md) px-2 flex"
      >
        <GatsbyImage image={image} tw="my-auto" />
        <span tw="px-1 my-auto">{name}</span>
      </a>

      {isDropdownOpen && (
        <ul tw="absolute left-1/2 transform -translate-x-1/2 top-full mt-0 bg-white text-gray-800 py-2 shadow-lg flex flex-row">
          <LeaguesMenu leagues={leagues} />
        </ul>
      )}
    </li>
  );
};

export default CountryMenu;
