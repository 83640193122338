import React from 'react';
import 'twin.macro';
import SportMenu from './sport-navigation-menu';

type NavigationMenuEntry = {
  slug?: string;
  title?: string;
  entries?: NavigationMenuEntry[];
  icon: object;
};

type NavigationMenuProps = {
  entries: NavigationMenuEntry[];
};

const NavigationMenu = ({ entries }: NavigationMenuProps) => {
  return (
    entries?.length > 0 && (
      <nav>
        <ul tw="flex justify-center space-x-4 mt-2">
          {entries?.map((entry, index) => {
            return (
              <SportMenu
                key={`sport-menu-${index}`}
                sport={entry.title}
                countries={entry.entries}
              />
            );
          })}
        </ul>
      </nav>
    )
  );
};

export default NavigationMenu;
