import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { PageProps } from 'gatsby';
import 'twin.macro';
import * as shortcodes from '../shortcodes';

import Header from './header';
import Footer from './footer';

const Layout = ({ children, ...rest }: PageProps) => (
  <MDXProvider components={shortcodes}>
    <div tw="container px-0 mx-auto">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  </MDXProvider>
);
export default Layout;
