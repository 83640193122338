import React from 'react';
import 'twin.macro';
import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer tw="bg-gray-800 text-white text-center py-4 mt-8">
      <div tw="container mx-auto">
        <p>&copy; 2024 Sport Fan Goods. All rights reserved.</p>
        <div tw="flex justify-center space-x-4 mt-2">
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms &amp; Conditions</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
