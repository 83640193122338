import React, { useEffect, useState } from 'react';
import 'twin.macro';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const MerchandiseCard = ({ merchandise }: MerchandiseCardProps) => {
  const [dataLayer, setDataLayer] = useState();

  const name = merchandise?.product?.name;
  const offers = merchandise?.product?.offers
    ?.filter(offer => offer?.url?.match(/^http.*/))
    ?.slice(0, 3);
  const image = getImage(
    merchandise?.childFile?.childImageSharp?.gatsbyImageData,
  );

  useEffect(() => {
    setDataLayer(window?.dataLayer);
  }, []);

  const onClick = e => {
    dataLayer && dataLayer.push({ event: 'affiliate-link-click' });
  };

  return (
    offers.length > 0 && (
      <div tw="rounded-lg border-2 p-5 max-w-96 mx-2 my-2">
        <div tw="flex flex p-5">
          <div>
            <GatsbyImage image={image} alt="merchandise" objectFit="contain" />
          </div>
          <div tw="px-2 basis-1/2">
            <span tw="text-center py-2">{name}</span>
          </div>
        </div>
        <div>
          <div tw="flex flex-col">
            {offers.map((offer, index) => {
              const url = offer?.url;
              const domain = `${url}`
                .match(/\/\/(.*?)\//)?.[1]
                ?.split('.')
                ?.slice(-2)
                ?.join('.');
              const price = offer?.priceSpecification?.price;
              const currency = offer?.priceSpecification?.priceCurrency;

              return (
                <a
                  key={index}
                  href={offer?.url}
                  target="_blank"
                  tw="text-center bg-gray-800 text-white my-1 w-full inline-block rounded px-2 py-2 text-sm font-medium transition hover:bg-gray-900"
                  onClick={onClick}
                >
                  {price && currency && (
                    <>
                      <span tw="float-left">{domain}</span>
                      <span tw="float-right">
                        ${price} ${currency}
                      </span>
                    </>
                  )}
                  {(!price || !currency) && (
                    <span tw="w-full">Buy on {domain}</span>
                  )}
                </a>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};

export default MerchandiseCard;

type MerchandiseCardProps = {
  merchandise: object;
};
