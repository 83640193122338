import React from 'react';
import { PageProps, graphql, useStaticQuery, Link } from 'gatsby';
import 'twin.macro';
import NavigationMenu from './navigation-menu';

const query = graphql`
  query SportCountryLeague {
    navigationMenu {
      header {
        title
        slug
        entries {
          slug
          title
          icon {
            childImageSharp {
              gatsbyImageData(height: 12, layout: FIXED)
            }
          }
          entries {
            slug
            title
            icon {
              childImageSharp {
                gatsbyImageData(height: 12, layout: FIXED)
              }
            }
            entries {
              slug
              title
              icon {
                childImageSharp {
                  gatsbyImageData(height: 12, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Header = () => {
  const data = useStaticQuery(query);
  const header = data?.navigationMenu?.header;
  return (
    <header tw="bg-primary text-white py-4">
      <div tw="container mx-auto">
        <Link to="/">
          <h1 tw="text-center text-2xl">Sport Fan Goods</h1>
        </Link>
        <NavigationMenu entries={header} />
      </div>
    </header>
  );
};

export default Header;
