import React, { useState } from 'react';
import 'twin.macro';
import ClubsMenu from './clubs-navigation-menu';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const LeaguesMenu = ({ leagues }) => {
  const [isLeagueDropdownOpen, setIsLeagueDropdownOpen] = useState(null);

  const handleMouseEnter = index => {
    setIsLeagueDropdownOpen(index);
  };

  const handleMouseLeave = () => {
    setIsLeagueDropdownOpen(null);
  };

  return (
    <>
      {leagues.map((league, index) => {
        const { icon, title } = league;
        const image = getImage(icon.childImageSharp.gatsbyImageData);

        return (
          <li
            key={index}
            className="group"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            tw="relative"
          >
            <a
              href={`#${title.toLowerCase().replace(/ /g, '-')}`}
              tw="block px-4 py-2 hover:bg-gray-200 whitespace-nowrap flex"
            >
              <GatsbyImage image={image} tw="my-auto" />
              <span tw="px-1 my-auto">{title}</span>
            </a>
            {isLeagueDropdownOpen === index && (
              <ClubsMenu clubs={league.entries} />
            )}
          </li>
        );
      })}
    </>
  );
};

export default LeaguesMenu;
