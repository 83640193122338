import React, { useContext } from 'react';
import { GraphQLContext } from '../contexts/graphql-context';
import MerchandiseCard from '../components/merchandise-card';
import 'twin.macro';

const MerchandiseItems = props => {
  const keys = Object.keys(props);
  const data = useContext(GraphQLContext);

  const merchandiseItems = data?.mdx?.merchandise?.filter(item =>
    keys.reduce((acc, key) => acc && props?.[key] === item?.[key], true),
  );

  return (
    <div tw="flex flex-wrap justify-center border-2 rounded-lg">
      {merchandiseItems.map((item, index) => (
        <MerchandiseCard key={index} jersey={item} />
      ))}
    </div>
  );
};

export default MerchandiseItems;
